import React from 'react';
import { Component } from 'react';
import { List, AutoSizer } from "react-virtualized";
import Song from '../../models/Song';
import './SongList.css'
import CheckIcon from '@material-ui/icons/Check';
import Request from '../../models/Request';

class SongListProps {
    songs : Song[] = [];
    isLauncherRunning : boolean = false;
    username? : string;
    onClick = (_song : Song) => { };
    requestHistory : Request[] = [];
}

export default class SongList extends Component<SongListProps> {
    myRef = React.createRef<List>();

    componentDidUpdate() {
        this.myRef.current.forceUpdateGrid();
    }

    render() {
        const rowHeight = 34;
        const rowCount = this.props.songs.length;

        return (
            <div className="artist-list">
                <AutoSizer>
                    {({height,width}) =>
                        <List
                            ref={this.myRef}
                            width={width}
                            height={height}
                            rowHeight={rowHeight}
                            rowRenderer={this.renderRow}
                            rowCount={rowCount}
                        />
                    }
                </AutoSizer>
            </div>
        );
    }

    renderRow = (item) => {
        const song = this.props.songs[item.index];
        const dateAdded = new Date(this.props.songs[item.index].DateAdded);
        const currentDate = new Date();
        const pastDate = new Date(currentDate);
        pastDate.setDate(pastDate.getDate() - 30);
        const isNew = dateAdded > pastDate;
        const historicalRequest = this.props.username ? this.props.requestHistory.find(x => x.FullName === song.FullName) : null;
        const isInHistory = historicalRequest && historicalRequest.Username === this.props.username;
        const offset = (isNew ? 15 : 0) + (isInHistory ? 20 : 0);
        const href = (
            <a href="/#" onClick={(e) => { this.click(e, song); return false; }}>
                {song.FullName}
            </a>
        );

        return (
            <div key={item.key} className="artist-row" style={item.style}>
                <div className="outer">
                    <div className="inner">
                        <div className="song-title" style={{maxWidth: 'calc(100% - ' + offset + 'px'}}>
                            {this.props.isLauncherRunning ? href : song.FullName}
                        </div>
                        <div className="icons">
                            { isNew &&
                                <sup><sup>new!</sup></sup>
                            }
                            { isInHistory &&
                                <CheckIcon className="check"></CheckIcon>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    click = (e : React.MouseEvent, song : Song) => {
        e.preventDefault();
        this.props.onClick(song);
    }
}