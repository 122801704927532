import React from 'react';
import { Component } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { Check } from '@material-ui/icons';
import Song from '../../models/Song';
import SongVersion from '../SongVersion/SongVersion';
import { TextField } from '@material-ui/core';
import { Block } from '@material-ui/icons';

class RequestSongModalProps {
    username? : string;
    isOpen = false;
    selectedSong : Song = new Song();
    close = () => {};
    usernameChanged = (username : string) => {};
    songRequested = (song : Song) => {};
}

export default class RequestRequestSongModal extends Component<RequestSongModalProps> {
    render() {
        const selectedSong = this.props.selectedSong;
        const username = this.props.username;
        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.props.close}
                aria-labelledby="request-alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                <div id="alert-dialog-description">
                    <span className="requested-song-title">
                    {selectedSong.FullName}
                    </span>
                    <SongVersion song={selectedSong}></SongVersion>
                    <TextField id="username-input" fullWidth label="Your name" variant="standard" size="small" onChange={(e) => {this.props.usernameChanged(e.target.value)}} value={username} />
                </div>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.props.close} color="secondary" variant="contained" startIcon={<Block />}>
                    Cancel
                </Button>
                <Button onClick={() => { this.props.songRequested(selectedSong) }} color="primary" variant="contained" autoFocus startIcon={<Check />}>
                    Request
                </Button>
                </DialogActions>
            </Dialog>
        );
    }
}