import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { ChangeEvent } from 'react';
import { Component } from 'react';
import SongList from './components/SongList/SongList';
import { TextField } from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { getAllSongs, getRequestHistory, getUserCount, requestSong } from './services/KaraokeService';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Request from './models/Request';
import Song from './models/Song';
import { Settings } from './configuration/Settings';
import FixedHeader from './components/FixedHeader/FixedHeader';
import RequestHistoryModal from './components/RequestHistoryModal/RequestHistoryModal';
import RequestSongModal from './components/RequestSongModal/RequestSongModal';

class AppState {
  songCount : number = 0;
  allSongs: Song[] = [];
  filteredSongs : Song[] = [];
  isLoaded = false;
  isAlertOpen = false;
  selectedSong : Song = new Song();
  isLauncherRunning = false;
  isSongRequestSuccessful = false;
  username: string = '';
  sortByAlphabetical = true;
  currentFilter: string = '';
  requestHistory: Request[] = [];
  childVisibility = new AppModalVisibility();
}

class AppModalVisibility implements IAppModalVisibility {
  history = false;
  requestSong = false;
}

interface IAppModalVisibility {
  history? : boolean;
  requestSong? : boolean;
}

export default class App extends Component<{}, AppState> {
  constructor(props) {
    super(props);

    this.state = new AppState();
  }

  darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
    },
  });

  componentDidMount() {
    getAllSongs().then(result => {
      this.setState({
        allSongs: result,
        filteredSongs: result,
        isLoaded: true
      });
    });
    
    this.refreshLauncherStatus();
    this.refreshRequestHistory();
  }

  refreshLauncherStatus = () => {
    getUserCount().then(result => {
      this.setState({
        isLauncherRunning: result > 0
      });
    });
  }

  generateRandomUsername = () => {
    return `Rando ${this.randomNumber(10000, 99999)}`;
  }

  randomNumber(min : number, max : number) {  
    return Math.floor(Math.random() * (max - min) + min); 
  }  

  requestSong = (song : Song) => {
    let username = this.state.username;

    if (!username) {
      username = this.generateRandomUsername();
      this.changeUsername(username);
    }

    let songRequest = new Request();

    Object.assign(songRequest, song, { DisableLogging : Settings.DISABLE_LOGGING});

    songRequest.Username = username;

    this.setChildVisibility({ requestSong: false});

    requestSong(songRequest).then(result => {
      this.setState({isSongRequestSuccessful: result, isAlertOpen: true});
      if (!result) {
        this.refreshLauncherStatus();
      }
    });
  }

  changeUsername = (username : string) => {
    this.setState({username});
  }

  songClicked = (song : Song) => {
    this.setState({
      selectedSong: song
    });
    this.setChildVisibility({ requestSong: true});
  }

  render() {
    return (
      <ThemeProvider theme={this.darkTheme}>
        <div className="App">
          { this.state.isLoaded &&
            <div>    
              <FixedHeader username={this.state.username} sortByAlphabetical={this.state.sortByAlphabetical} isHistoryDialogOpen={this.state.childVisibility.history} changeOrderClicked={() => this.onChangeOrderClicked()} historyClicked={() => this.onHistoryClicked() }></FixedHeader>
              <div className="App-header container">
                {Settings.IS_DEBUG &&
                  <div className="debug-info">
                    <h1>Songs loaded: {this.state.allSongs.length}</h1>
                    <h1>Filtered songs: {this.state.filteredSongs.length}</h1>
                  </div>
                }
                <div className="search">
                  <TextField id="outlined-basic" fullWidth label="Search" variant="filled" type="search" onChange={this.onSearchChanged} />
                </div>
                <div className="content">
                  <SongList songs={this.state.filteredSongs} onClick={song => { this.songClicked(song) }} isLauncherRunning={this.state.isLauncherRunning} username={this.state.username} requestHistory={this.state.requestHistory}></SongList>
                </div>
                <RequestSongModal username={this.state.username} selectedSong={this.state.selectedSong} isOpen={this.state.childVisibility.requestSong} close={() => { this.setChildVisibility({ requestSong: false })}} usernameChanged={this.changeUsername} songRequested={this.requestSong} />
                <RequestHistoryModal requestHistory={this.state.requestHistory} username={this.state.username} isOpen={this.state.childVisibility.history} close={() => {this.setChildVisibility({ history: false })}} />
                {this.renderSongRequestAlert()}
              </div>
            </div>
          }
          {
            !this.state.isLoaded &&
            <div className="loading-area"></div>
          }
        </div>
      </ThemeProvider>
    );
  }

  renderSongRequestAlert() {
    return (
      <Snackbar open={this.state.isAlertOpen} autoHideDuration={3000} onClose={() => this.setState({isAlertOpen: false})} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <MuiAlert onClose={() => this.setState({isAlertOpen: false})} severity={this.state.isSongRequestSuccessful ? 'success' : 'error'} variant="filled">
          {this.state.isSongRequestSuccessful &&
            <span>Song requested!</span>
          }
          {!this.state.isSongRequestSuccessful &&
            <span>Song not requested :(</span>
          }
        </MuiAlert>
      </Snackbar>
    );
  }

  onSearchChanged = (e : ChangeEvent<HTMLInputElement>) => {
    this.setState({currentFilter: e.target.value}, () => this.filterSongs());
  }

  filterSongs = () => {
    const filter = this.state.currentFilter;

    let filteredSongs : Song[] = [];

    if (!filter) {
      filteredSongs = this.state.allSongs;
    }
    else {
      if (filter.endsWith('*')) {
        filteredSongs = this.state.allSongs.filter(x => x.FullName.toLowerCase().startsWith(filter.replace('*','')));
      }
      else {
        filteredSongs = this.state.allSongs.filter(x => x.FullName.toLowerCase().includes(filter.toLowerCase()));
      }
    }

    if (this.state.sortByAlphabetical) {
      filteredSongs = filteredSongs.sort((a,b) => (a.FullName > b.FullName) ? 1 : -1);
    }
    else {
      filteredSongs = filteredSongs.sort((a,b) => { 
        if (a.DateAdded === b.DateAdded) {
          return a.FullName > b.FullName ? 1 : -1;
        }
        return (a.DateAdded < b.DateAdded) ? 1 : -1 
      });
    }

    if (!this.state.currentFilter) {
      filteredSongs = filteredSongs.filter(x => !x.IsHidden);
    }

    this.setState({filteredSongs: filteredSongs});
  }

  onChangeOrderClicked() {
    this.setState({sortByAlphabetical: !this.state.sortByAlphabetical}, () => {
      this.filterSongs();
    });
  }

  onHistoryClicked() {
    if (this.state.childVisibility.history) {
      return;
    }

    this.setChildVisibility({ history: true });
    this.refreshRequestHistory();
  }

  setChildVisibility(modalVisibility : IAppModalVisibility) {
    let newModalVisibility = Object.assign({}, this.state.childVisibility);
    Object.assign(newModalVisibility, modalVisibility);
    this.setState({ childVisibility: newModalVisibility });
  }

  refreshRequestHistory() {
    getRequestHistory().then(result => {
      this.setState({requestHistory: result.filter(x => !this.state.username || x.Username === this.state.username)});
    });
  }
}