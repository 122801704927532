import React from "react";
import { Component } from "react";
import Song from '../../models/Song';
import { getVersionColorHex } from '../../services/KaraokeService' ;

class SongVersionProps {
    song? : Song;
}

export default class SongVersion extends Component<SongVersionProps> {
    render() {
        if (!this.props.song) {
            return <span></span>;
        }

        const color = getVersionColorHex(this.props.song.Version);

        return (
            <span style={{color: color}}>
                &nbsp;({this.props.song.Version || '?'})
            </span>
        )
    }
}