export const colors = {
    '?': '#FF007C',
    '#Z': '#9600FF',
    'AB': '#004BFF',
    'AD': '#7FFF00',
    'AH': '#00FEFF',
    'AK': '#FF6D00',
    'AMS': '#FFAB00',
    'AR': '#00A1FF',
    'AS': '#FF4600',
    'ASING': '#A600FF',
    'ATOY': '#12FF00',
    'AUSTRAILIAN': '#F400FF',
    'AUSTRALIAN': '#60FF00',
    'AX': '#3900FF',
    'BB': '#FF0055',
    'BH': '#FFEA00',
    'BK': '#77FF00',
    'BKD': '#96FF00',
    'BKK': '#8FFF00',
    'BLACK BELL': '#FFCA00',
    'BMG': '#FFDA00',
    'BMI': '#0091FF',
    'BNI': '#00FF14',
    'BRI': '#FF0000',
    'BS': '#FF006D',
    'C': '#00FFD7',
    'CC': '#42F5DA',
    'CAPITOL': '#00FFC8',
    'CARE BEARS': '#FF00AB',
    'CAROL': '#21FF00',
    'CAVES': '#0014FF',
    'CB': '#CDFF00',
    'CD': '#FF2700',
    'CELTIC': '#FF5500',
    'CH': '#FF6500',
    'CK': '#001CFF',
    'CMC': '#FF0F00',
    'CT': '#E400FF',
    'D': '#7F00FF',
    'DANISH': '#1A00FF',
    'DC': '#FFF900',
    'DF': '#00FF1C',
    'DG': '#00FFEF',
    'DIGITRAX': '#2900FF',
    'DISNEY': '#50FF00',
    'DJG': '#8700FF',
    'DK': '#0082FF',
    'DM': '#00FF53',
    'DMG': '#4800FF',
    'DP': '#FF00DA',
    'DT': '#FFB300',
    'DW': '#FF0017',
    'EK': '#0072FF',
    'EL': '#FF00F1',
    'ETT': '#B6FF00',
    'EZ': '#1200FF',
    'FB': '#00C0FF',
    'FRENCH': '#BDFF00',
    'FUN': '#00FFDF',
    'FUNTIME': '#00FF3B',
    'GERMAN': '#00FFD0',
    'GREEK': '#FF00EA',
    'H': '#FF00A3',
    'HD': '#FF1700',
    'HEBREW': '#FF0000',
    'HECTOR': '#FF8C00',
    'HEWBREW': '#7700FF',
    'HK': '#00FF89',
    'HM': '#FF9400',
    'HOMESTAR': '#00F7FF',
    'HP': '#6800FF',
    'HS': '#FC00FF',
    'HV': '#FF0065',
    'IP': '#BD00FF',
    'IRISH': '#00FFF7',
    'ITALIAN': '#0AFF00',
    'JEWISH': '#FCFF00',
    'JS': '#00FFB0',
    'JVC': '#FF000F',
    'K': '#0062FF',
    'K2G': '#FF2E00',
    'KAK': '#FF3600',
    'KARAOKE KID': '#00FF2C',
    'KB': '#5000FF',
    'KC': '#48FF00',
    'KCD': '#00FFB8',
    'KCH': '#41FF00',
    'KF': '#FFE200',
    'KLE': '#AEFF00',
    'KM': '#FF4E00',
    'KP': '#FF1F00',
    'KRG': '#5800FF',
    'KS': '#ECFF00',
    'KV': '#FFBB00',
    'KZ': '#FF008C',
    'L': '#003BFF',
    'LD': '#FFD200',
    'LG': '#DD00FF',
    'LK': '#87FF00',
    'MADACY': '#00FF62',
    'MALTESE': '#00D7FF',
    'MAORI': '#00FFC0',
    'MC': '#02FF00',
    'MD': '#00C8FF',
    'ME': '#00FF82',
    'MEGA': '#005BFF',
    'MF': '#FF002E',
    'MH': '#EC00FF',
    'MIC': '#C500FF',
    'MM': '#00FF34',
    'MM SPANISH': '#007AFF',
    'MP': '#00FF0D',
    'MT': '#58FF00',
    'MYFANWY': '#FFA300',
    'NS': '#00E7FF',
    'NSTAR': '#00FFA9',
    'NU': '#FF5D00',
    'NUB': '#9EFF00',
    'NUK': '#002CFF',
    'NZ': '#FF8400',
    'OK': '#CA2C92',
    'P': '#C5FF00',
    'PH': '#FF0027',
    'PHM': '#FF7C00',
    'PHP': '#8F00FF',
    'PI': '#FF009C',
    'PM': '#6000FF',
    'PMC': '#9E00FF',
    'PMM': '#D500FF',
    'PN': '#00FFA1',
    'PR': '#FF3E00',
    'PRIDDIS': '#AE00FF',
    'PS': '#E4FF00',
    'PT': '#00A9FF',
    'PX': '#6FFF00',
    'QH': '#4100FF',
    'RCA': '#00FF43',
    'RD': '#FF00F9',
    'RHM': '#FF003E',
    'ROB DOG': '#B600FF',
    'ROK BOX': '#FF00C3',
    'ROX BOX': '#3100FF',
    'RS': '#FF0046',
    'S STAR': '#FF005D',
    'SA': '#0005FF',
    'SAVC': '#FF0094',
    'SB': '#000DFF',
    'SBI': '#FF7500',
    'SC': '#39FF00',
    'SCOTTISH': '#0099FF',
    'SD': '#00FF99',
    'SF': '#00DFFF',
    'SFY': '#FF0036',
    'SG': '#00FF6A',
    'SGB': '#0089FF',
    'SGK': '#FF9C00',
    'SH': '#6F00FF',
    'SILVER SADDLE': '#00FF24',
    'SINGC': '#00D0FF',
    'SINGER CHOICE': '#31FF00',
    'SINGERS C': '#FF00E2',
    'SINGER\'S C': '#FF00D2',
    'SINGERS CHOICE': '#68FF00',
    'SK': '#FF001F',
    'SM': '#FF00BB',
    'SN': '#DDFF00',
    'SO': '#FF004E',
    'SONY': '#00EFFF',
    'SPANISH': '#00B0FF',
    'SR': '#FFC300',
    'SS': '#00FF4B',
    'ST': '#F4FF00',
    'STELLAR': '#0053FF',
    'STW': '#0200FF',
    'SUNDOWN': '#00B8FF',
    'SV': '#CD00FF',
    'SVENSKA': '#00FFE7',
    'SW': '#FF0075',
    'SWEDISH': '#FF0700',
    'TB': '#2100FF',
    'TG': '#FF0084',
    'TH': '#FF00B3',
    'THM': '#0A00FF',
    'TRADITIONAL': '#0043FF',
    'TRAKRITE': '#0024FF',
    'TRAX': '#0034FF',
    'TSM': '#FFF100',
    'TT': '#29FF00',
    'TZ': '#1AFF00',
    'UB': '#00FF72',
    'UK': '#00FF05',
    'UK MEGA': '#D5FF00',
    'US': '#00FF91',
    'VD': '#006AFF',
    'VS': '#A6FF00',
    'WC': '#00FF5B',
    'X': '#00FF7A'    
};