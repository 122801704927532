import { Settings } from '../configuration/Settings';
import Song from '../models/Song';
import Request from '../models/Request';
import { colors } from '../constants';

export class KaraokeService {
}

export function getAllSongs() : Promise<Song[]> {
    return get('songs/all');
}

export function requestSong(song : Request) : Promise<boolean> {
    return post('request', song);
}

export function getUserCount() : Promise<number> {
    return get('usercount');
}

export function getRequestHistory() : Promise<Request[]> {
    return get('requests');
}

export function getVersionColorHex(version : string) : string {
    return colors[version || '?'];
}

export function getHiMsg() : string {
    return 'O hai';
}

function get<T>(endpoint : string) : Promise<T> {
    return fetch(Settings.API_ROOT_URL + endpoint).then(response => response.json());
}

function post<I,O>(endpoint : string, data : I) : Promise<O> {
    return fetch(Settings.API_ROOT_URL + endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => response.json());
}