import React, { Component } from "react";
import HistoryIcon from '@material-ui/icons/History';
import BarChartRoundedIcon from '@material-ui/icons/BarChartRounded';
import cn from 'classnames';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

import './FixedHeader.css';

class FixedHeaderProps {
    username = '';
    sortByAlphabetical = false;
    isHistoryDialogOpen = false;
    changeOrderClicked = () => { };
    historyClicked = () => { };
}

export default class FixedHeader extends Component<FixedHeaderProps> {
    render() {
        const sortClasses = cn([
            {'history-icon': true},
            {'highlighted': !this.props.sortByAlphabetical},
        ]);

        const historyClasses = cn([
            {'highlighted': this.props.isHistoryDialogOpen}
        ]);

        return (
            <div className="fixed-header">
                <div className="fixed-header-icons">
                    <div className="fixed-header-username-area">
                        <PersonOutlineOutlinedIcon></PersonOutlineOutlinedIcon>
                        <div className="fixed-header-username">{this.props.username}</div>
                    </div>
                    <div className="fixed-header-options">
                        <BarChartRoundedIcon className={historyClasses} onClick = {e => this.historyClicked(e)}></BarChartRoundedIcon>
                        <HistoryIcon className={sortClasses} onClick={e => this.changeOrderClicked(e)}></HistoryIcon>
                    </div>
                </div>
            </div>
        );
    }

    changeOrderClicked(e : React.MouseEvent) {
        this.props.changeOrderClicked();
    }

    historyClicked(e: React.MouseEvent) {
        this.props.historyClicked();
    }
}