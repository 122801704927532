import React from 'react';
import { Component } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Request from '../../models/Request';
import Button from '@material-ui/core/Button';
import { Check } from '@material-ui/icons';

class RequestHistoryModalProps {
    requestHistory : Request[] = [];
    username? : string;
    isOpen = false;
    close = () => {};
}

export default class RequestHistoryModal extends Component<RequestHistoryModalProps> {
    render() {
        const hasContent = this.props.requestHistory && this.props.requestHistory.length > 0;
        return (
          <Dialog
            open={this.props.isOpen}
            onClose={() => this.setState({isHistoryDialogOpen: false})}
          >
            <DialogContent>
              <div className='history-content'>
                <h4>
                  {!this.props.username &&
                    <span>All requests</span>
                  }
                  {this.props.username &&
                    <span>Requests by {this.props.username}</span>
                  }
                </h4>
                {hasContent && 
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Song</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.requestHistory.map((request, idx) => {
                          return (
                              <tr key={idx}>
                                <td>{request.Username}</td>
                                <td>{request.FullName}</td>
                                <td>{new Date(request.Time).toLocaleDateString()}</td>
                              </tr> 
                          );
                        })}
                    </tbody>
                  </table>
                }
                {!hasContent &&
                  <div>No request history found!</div>
                }
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.props.close()} color="primary" variant="contained" startIcon={<Check />}>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        );
      }
}